import { format } from 'date-fns';
import { mix } from '@theme-ui/color';
import Footer from "../../../../src/components/footer";
import Socials from "../../../../src/components/socials";
import * as React from 'react';
export default {
  format,
  mix,
  Footer,
  Socials,
  React
};